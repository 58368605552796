import PropTypes from "prop-types"

export const ACCOUNT_TYPE_CURRENT = "CURRENT"

export const CURRENCY_TYPE_IQD = "IQD"
export const CURRENCY_TYPE_USD = "USD"
export const CURRENCY_TYPE_EUR = "EUR"

export const CurrencyPropTypes = PropTypes.oneOf([
  CURRENCY_TYPE_IQD,
  CURRENCY_TYPE_USD,
  CURRENCY_TYPE_EUR,
])

export const MonetaryValuePropTypes = PropTypes.shape({
  amount: PropTypes.number.isRequired,
  currency: CurrencyPropTypes.isRequired,
})

/**
 * @typedef { CURRENCY_TYPE_IQD | CURRENCY_TYPE_USD | CURRENCY_TYPE_EUR } CURRENCY_TYPE
 */

export const TRANSACTION_TYPE_RECEIVED = "RECEIVED"
export const TRANSACTION_TYPE_SENT = "SENT"
export const TRANSACTION_TYPE_ALL = "ALL"

export const GROUPED_TRANSACTION_TYPES = [
  TRANSACTION_TYPE_ALL,
  TRANSACTION_TYPE_SENT,
  TRANSACTION_TYPE_RECEIVED,
]

export const GROUPED_LOKALISE_KEY_TRANSACTION_TYPES = {
  [TRANSACTION_TYPE_ALL]:
    "web_c_transationhistory_filters_transactiontypelist_all_label",
  [TRANSACTION_TYPE_SENT]:
    "web_c_transationhistory_filters_transactiontypelist_sent_label",
  [TRANSACTION_TYPE_RECEIVED]:
    "web_c_transationhistory_filters_transactiontypelist_received_label",
}

export const OPERATION_STATUS_GROUP_PENDING = "PENDING"
export const OPERATION_STATUS_GROUP_COMPLETED = "COMPLETED"
export const OPERATION_STATUS_GROUP_BLOCKED_FUNDS = "BLOCKED_FUNDS"

export const GROUPED_OPERATION_STATUS_GROUPS = [
  OPERATION_STATUS_GROUP_PENDING,
  OPERATION_STATUS_GROUP_COMPLETED,
  OPERATION_STATUS_GROUP_BLOCKED_FUNDS,
]

export const MAP_OPERATION_STATUS_GROUP_TO_LOKALISE_KEY = {
  [OPERATION_STATUS_GROUP_PENDING]:
    "web_c_transationhistory_filters_transactionstatuslist_pending",
  [OPERATION_STATUS_GROUP_COMPLETED]:
    "web_c_transationhistory_filters_transactionstatuslist_completed",
  [OPERATION_STATUS_GROUP_BLOCKED_FUNDS]:
    "web_c_transationhistory_filters_transactionstatuslist_blockedfunds",
}
